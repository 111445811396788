<template>
  <div class="container">
    <div class="content">
      <div class="tab_menu" >      
        <div class="item" 
        v-for="(item,index) in tablist" 
        :key="'tab'+index"
        :class="type == item.type?'cur':''"
        @click="handleClick(index)"
        >{{item.name}}</div>            
      </div>
      <div class="list" ref="wrapper" 
        v-infinite-scroll="loadData" 
        infinite-scroll-disabled="disabled" 
        >
          <div class="item"
            v-for="(item, index) in teacherList"
            :key="'expert' + index"
            @click="openBlank('WapExpertDetails', item.teacherId)">
            <img class="item-img" :src="item.image" alt="" />
            <div class="info">
              <div class="name">{{ item.name }}</div>
              <div class="college">
                <div
                  class="txt"
                  v-for="(txt, j) in item.positionalTitles"
                  :key="'txt' + j"
                >
                  {{ txt }}
                </div>
              </div>
            </div>
          </div>
          <div v-if="noMore && !!teacherList.length" class="no_more">-没有更多了-</div>
      </div>

      <el-empty description="暂无数据" v-if="!teacherList.length"></el-empty>
    </div>
  </div>
</template>
  
<script>
export default {
  props:['fixHeight'],
  data() {
    return {
      page: 1,
      type: 4,
      tablist:[
        {name:'编委会委员',type:4},
        {name:'专家委员会',type:1},
        {name:'专家顾问',type:2},
        {name:'讲师',type:3}
      ],
      teacherList: [],
      loading:false,
      noMore:false,
    };
  },  
  mounted() {
    // this.getTeacherList();
  },
  computed: {
    disabled () {
      //当两个都为真的时候不加载,页面滚动失效
      return this.loading || this.noMore
    }
  },
  methods: {
    loadData(){
      this.loading = true
      this.getTeacherList()
        
      // setTimeout(() => {
      //   this.getTeacherList()
      //   this.loading = false
      // }, 2000)
    },
    getTeacherList() {
      const data = {
        page: this.page,
        type: this.type,
      };
      this.$http.post("/api/wap/teacher/getList", data).then((res) => {
        const {teacherList,totalPage }=res.data;
        this.teacherList = this.teacherList.concat(teacherList);
        this.page++;
        this.loading = false
				if (this.page > totalPage) {
					this.noMore=true;
				} 
      });
    },
    handleClick(i) {
      this.type= this.tablist[i].type;
      this.page = 1;
      this.teacherList = [];
      this.loading = false;
      this.noMore = false;
      this.getTeacherList();
    },
    openBlank(name, id) {
      let routeUrl = this.$router.resolve({
        name,
        query: { id },
      });
      window.open(routeUrl.href, "_self");
    },
  },
};
</script>

<style lang='less' scoped>
.container {
  padding:0 0 .3rem;
  background: #f7f7f7;
  .content {
    width: 100%;
  }
  /deep/.tab_menu{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding:0 .3rem ;
    margin-top: 1px;
    background-color: #fff;
    z-index: 10;
    .item{
      font-size: .30rem;
      color: #333;
      padding: .3rem 0; 
      &.cur{
        position: relative;        
      } 
      &.cur::after{
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: .04rem;
        background-color: #d71a18;
      }  
    }
  }
  .list {
    width: 100%;
    padding: .3rem .3rem 0;
    .item {
      width: 100%;
      margin-bottom: 0.4rem;
      display: flex;
      background: #fff;
      padding: 0.15rem;
      border-radius: 0.12rem;
      .item-img {
        width: 2.8rem;
        height: 3.7rem;
        border-radius: 0.1rem;
        cursor: pointer;
        object-fit: cover;
        object-position: center;
      }
      .info {
        width: calc(100% - 2.8rem);
        padding-left: 0.3rem;

        .name {
          font-weight: 700;
          font-size: 0.36rem;
          padding: 0.15rem 0 0.3rem;
          color: #333;
          text-align: left;
          border-bottom: solid 1px #f5f5f5;
        }
        .college {
          width: 100%;
          color: #666;
          text-align: left;
          padding-top: 0.3rem;
          .txt {
            font-size: 0.24rem;
            line-height: 0.4rem;
            color: #666;
            margin-bottom: 0.3rem;
          }
        }
      }
    }
  }
  .no_more{
    font-size: .28rem;
    color: #666;
  }
}
</style>
